<template>
<div class="container">
    <el-menu class="sidebar-menu" active-text-color="#fff" :unique-opened="true" :default-active="$route.path">
        <div v-for="(item,i) of showList" :key="i">
            <el-submenu v-if="auth(...item.meta.auth) && item.children.length > 1" class="sidebar-menu-group-item" :index="item.path">
                <template slot="title">
                    <div class="sidebar-menu-title">
                        <div class="sidebar-menu-icon">
                            <i class="iconfont" :class="item.meta.icon"></i>
                        </div>
                        <span>{{item.meta.title}}</span>
                    </div>
                </template>
                <el-menu-item v-for="(child,j) in item.children" :key="i+'-'+j" @click="routerChange(child)"
                    :index="child.path" class="sidebar-menu-item"
                    :class="{'is-active': child.path.includes(activePath[1])}">{{child.meta.title}}</el-menu-item>
            </el-submenu>

            <el-menu-item v-else-if="auth(...item.meta.auth)" class="sidebar-menu-item" @click="routerChange(item)"
                :index="item.path" :class="{'is-active': item.path.includes(activePath[0])}" >
                <div class="sidebar-menu-title">
                    <div class="sidebar-menu-icon">
                        <i class="iconfont" :class="item.meta.icon"></i>
                    </div>
                    <span slot="title">{{item.meta.title}}</span>
                </div>
            </el-menu-item>
        </div>
    </el-menu>
    <MyPowered/>
</div>
</template>

<script>
import Core from '@/core';
import Const from '@/core/const';
import routes from '@/router/routes';

const USER_TYPE = Const.USER_TYPE;

export default {
    components: {
        MyPowered: () => import('@/components/Powered')
    },
    data() {
        return {
            USER_TYPE,
            userType: Core.Data.getUserType(),
            activePath: '',
            systemIndex: 1,
        };
    },
    computed: {
        showList() {
            let showList = routes.filter(item => {
                const roles = item.meta ? item.meta.roles : '';
                const classify = item.meta ? item.meta.classify : '';
                return (roles ? (roles.includes(this.userType) && !item.meta.hidden) : !item.meta.hidden) && classify == this.systemIndex;
            });

            showList.forEach(item => {
                item.children = item.children.filter((i) => {
                    const roles = i.meta ? i.meta.roles : '';
                    const classify = i.meta ? i.meta.classify : '';
                    return (roles ? (roles.includes(this.userType) && !i.meta.hidden) : !i.meta.hidden) && classify == this.systemIndex;
                });
            })
            console.log('showList:', showList)
            if (this.auth('LIMIT_SERVICE')) {
                showList = showList.filter(item => {
                    return !item.path.includes('resources') && !item.path.includes('plan')
                });
            }
            if (this.auth('CHANNE_CUSTOMERS')) {
                showList = showList.filter(item => {
                    return !item.path.includes('system-service')
                });
            }
            return showList
        },
    },
    watch: {
        $route: {
            deep: true,
            immediate: true,
            handler(newRoute) {
                this.systemIndex = newRoute.meta ? newRoute.meta.classify || 1 : 1;

                let path = newRoute.path.split('/')
                console.log('path:', path)
                this.activePath = [path[1], path[path.length-1]];
                console.log('this.activePath:', this.activePath)
                if (this.auth('LIMIT_SERVICE')) {
                    let path = this.$route.path
                    if (path.includes('resources') || path.includes('plan')) {
                        this.$router.replace('/account/account-list')
                    }
                }
            }
        }
    },
    created() {
    },
    mounted() {
        if (this.auth('LIMIT_SERVICE')) {
            let path = this.$route.path
            if (path.includes('resources') || path.includes('plan')) {
                this.$router.replace('/account/account-list')
            }
        }
    },
    methods: {
        auth: Core.Utils.auth,
        routerChange(item) {
            this.$router.push(item.path);
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    .sidebar-menu {
        @include no-select;
        border: none;
        width: 100%;
        height: auto;
        background-color: #fff;
        .sidebar-menu-item {
            @include ftb;
            height: 44px;
            color: $color-black-light;
            font-size: 14px;
            line-height: 20px;
            border-radius: 2px;

            .sidebar-menu-icon {
                margin-right: 14px;
                overflow: hidden;
                position: relative;
                i.iconfont {
                    font-size: 14px;
                    color: #8090a6;
                }
            }
            &:hover {
                background-color: #e6eaee;
            }
            &.is-active {
                background-color: #1d517e;
                color: #ffffff;
                i.iconfont {
                    font-size: 14px;
                    color: #ffffff;
                }
            }
        }
    }
    .powered {
        position: absolute;
        bottom: 10px;
        left: -5px;
    }
}
</style>
<style lang="scss">
.sidebar-menu {
    .sidebar-menu-group-item .el-submenu__title {
        @include ftb;
        height: 44px;
        color: $color-black-light;
        font-size: 14px;
        line-height: 20px;
        border-radius: 2px;
        &:hover {
            background-color: #e6eaee;
        }
        .sidebar-menu-icon {
            margin-right: 14px;
            overflow: hidden;
            position: relative;
            i.iconfont {
                font-size: 14px;
                color: #8090a6;
            }
        }
    }
    .sidebar-menu-title {
        @include ftb;
    }
}
</style>